import {
  useCallback, useState,
} from 'react';
import { FormattedMessage } from 'react-intl';
import { useLocation, useParams } from 'react-router-dom';

import Button from '../../Component/Button/Button';
import Container from '../../Component/Container/Container';
import FlexRow from '../../Component/FlexRow/FlexRow';
import Form from '../../Component/Form/Form';
import FormAddon from '../../Component/FormAddon/FormAddon';
import FormAddonWrapper from '../../Component/FormAddonWrapper/FormAddonWrapper';
import FormControl from '../../Component/FormControl/FormControl';
import FormFieldset from '../../Component/FormFieldset/FormFieldset';
import HeaderRetour from '../../Component/Header_Retour/header_retour';
import { makeRoutePath } from '../../store/route/route.api';
import useShowBienStore from '../ShowBienLayout/useShowBienStore';
import './DetailRoomBienPage.scss';
import FormLabel from '../../Component/FormLabel/FormLabel';
import FormInput from '../../Component/FormInput/FormInput';

import { useForm } from 'react-hook-form';

import FormSelect from '../../Component/FormSelect/FormSelect';
import IconGallery from '../../Component/Icon/IconGallery';
import ModalGlow from '../../Component/Modal/ModalGlow/ModalGlow';
import Modal from '../../Component/Modal/Modal';
import ModalAlerte from '../../Component/Modal/ModalAlerte/ModalAlerte';
import ModalInfoMedium from '../../Component/Modal/Modal_Info/ModalInfoMedium/ModalInfoMedium';
import ModalInfoLight from '../../Component/Modal/Modal_Info/ModalInfoLight/ModalInfoLight';
import MODAL_TITRE from '../../Component/Modal/Modal_Titre/modal_titre';
import ModalGrid from '../../Component/Modal/ModalGrid/ModalGrid';
import { bienMocked } from '../MesBiensPage/BienMocked';

export default function DetailRoomBienPage(readOnly = 'true') {
  const [open, setOpen] = useState(false);
  const [moreInfo, setMoreInfo] = useState([]);
  const { bien } = useShowBienStore();
  const { bienId } = useParams();
  const { roomId } = useParams();

  // const bien = bienMocked;

  const handleOpen = useCallback((valueFotThisLabel) => {
    setOpen((previousShow) => !previousShow);
    setMoreInfo(valueFotThisLabel);
  }, []);
  const handleHide = useCallback(() => {
    setOpen((previousShow) => !previousShow);
    setMoreInfo([]);
  }, []);

  const surfaceCadastraleObject = {
    title: 'Surface cadastrale',
    type: 'number',
    slug: 'surfaceCadastral',
    unit: 'm²',
    value: [bien.surfaceCadastrale],
  };

  const sectionsCopy = bien.sections.map((section) => {
    if (section.title === 'Informations') {
      const surfaceExtField = section.fields.find((field) => field.title === 'Surface extérieure');
      const newFields = [...section.fields];
      newFields.splice(surfaceExtField ? newFields.findIndex((field) => field.title === 'Surface extérieure') + 1 : newFields.findIndex((field) => field.title === 'Surface hab.') + 1, 0, surfaceCadastraleObject);

      return { ...section, fields: newFields };
    }

    return section;
  });

  const location = useLocation();
  const form = useForm({
    mode: 'onTouched',
  });

  const findObjectByKey = (array, key, value) => array?.find((element) => element[key] === value);
  const pieceActuel = findObjectByKey(bien?.pieces, 'id', roomId);

  if (pieceActuel) {
    const sameTitlePieces = bien?.pieces.filter((piece) => piece.nom === pieceActuel.nom) || [];
    const positionInSameTitlePieces = sameTitlePieces.findIndex((piece) => piece.id === pieceActuel.id) + 1;
    if (positionInSameTitlePieces > 1) {
      pieceActuel.nom = `${pieceActuel.nom} ${positionInSameTitlePieces}`;
    }
  }

  const sectionGeneral = sectionsCopy;

  const infoPieces = (array) => array?.fields.map((field) => {
    const validTypesInput = [
      'single',
      'date',
      'text',
      'streetAddress',
      'city',
      'postCode',
      'year',
      'date',
    ];
    const validTypesSelect = [
      'multi',
      'signedNumber',
    ];

    if (field?.value?.length === 0 || field?.value === null) {
      return null;
    }
    if (field?.type === 'number' && field?.value?.length > 0) {
      return (

        <FormControl key={field?.title} name={field?.title}>
          <FormLabel>
            {field?.title}
          </FormLabel>
          <FormAddonWrapper>
            <FormInput readOnly={readOnly} type={field?.type} valueReadOnly={field.value / 100} />
            <FormAddon side="right">{field?.unit}</FormAddon>
          </FormAddonWrapper>
        </FormControl>

      );
    }
    if (validTypesInput.includes(field?.type) && field?.value?.length > 0) {
      const adresse = field?.type === 'streetAddress' ? 'adressePostalRowInGrid' : '';
      const value = field?.type === 'date' ? String(field?.value).split(' ')[0] : field?.value;

      return (

        <FormControl className={adresse} key={field?.title} name={field?.title}>
          <FormLabel>
            {field?.title}
          </FormLabel>
          <FormInput readOnly={readOnly} type={field?.type} valueReadOnly={value} />
        </FormControl>

      );
    }

    if (validTypesSelect.includes(field?.type) && field?.value?.length > 0) {
      const valueArrayReadOnly = field?.value?.length > 1 ? (
        <>
          <p className="FormSelect__readonly_P">
            {field?.value[0]}
          </p>
          +
          {' '}
          {field.value.length - 1}

        </>
      ) : field.value;

      return (
        readOnly
          ? (
            <FormControl key={field?.title} name={field?.title}>
              <FormLabel>
                {field?.title}
              </FormLabel>
              <FormInput isMultiReadOnly={field?.value?.length > 1} onClick={() => (handleOpen(field))} readOnly={readOnly} type={field?.type} valueReadOnly={valueArrayReadOnly} />
            </FormControl>
          )
          : (
            <FormControl key={field?.title} name={field?.title}>
              <FormLabel>
                {field?.title}
              </FormLabel>
              <FormSelect
                valueReadOnly={field?.value.map((value) => ({
                  label: value,
                  value,
                }))}
                isMulti
              />
            </FormControl>
          )

      );
    }

    return `Aucun element n'a été trouver car ${field?.type}`;
  });

  return (

    <>
      <div className="containerHeader_BackTitle">
        <div className="container_TitleRoomId">
          {roomId === 'general' ? 'GÉNÉRALITÉS' : pieceActuel?.nom}
        </div>
        <HeaderRetour
          classBEM="_homeAchatVenteBienBack"
          to={makeRoutePath('bien.show.details', { bienId })}
          transparent
        />
      </div>
      {open ? (
        <ModalGrid
          title={moreInfo?.title}
          onClose={handleHide}
        >
          <Form className="FormDetailRoomBien" {...form}>
            <FormControl key={moreInfo?.title} name={moreInfo?.title}>
              <div className="gridInfo">
                {moreInfo?.value.map((i) => (
                  <FormInput className="FormInput-moreInfo" isMultiReadOnly={i?.value?.length > 1} readOnly={readOnly} type={moreInfo?.type} valueReadOnly={i} />
                ))}
              </div>
            </FormControl>
          </Form>
        </ModalGrid>

      ) : ''}
      <Container
        size="lg"
      >
        <FlexRow
          className="container_DetailRoomBien"
          direction="column"
          grow="1"
          alignItems="center"
          justifyContent="space-evenly"
          gap="2rem"
        >
          <Button
            className="button submit yellow"
            to={pieceActuel && pieceActuel?.photos?.length > 0 ? makeRoutePath('bien.show.photos', { bienId, roomId, backTo: location.pathname }) : sectionGeneral && bien?.photos?.length > 0 ? makeRoutePath('bien.show.photos', { bienId, backTo: location.pathname }) : '#'}
            ariaLabel="voir les photos de la piece"
            icon={<IconGallery className="img-wxl img-hxl" />}
          >
            <FormattedMessage
              id="label.numberPicture"
              values={{
                count: pieceActuel
                  ? pieceActuel?.photos?.length : bien.photos?.length,
              }}
            />
          </Button>
          <Form className="FormDetailRoomBien" {...form}>
            {roomId === 'general'
              ? (

                sectionGeneral.map((section) => {
                  const hasFieldWithValue = section?.fields?.some((field) => field?.value && field?.value.length > 0);

                  return hasFieldWithValue ? (

                    <FormFieldset className="legendeDetailBien" margin="0px 0px 2rem 0px" legend={section?.title} direction="row-wrap">
                      <div className="grid">

                        {infoPieces(section)}
                      </div>
                    </FormFieldset>
                  ) : '';
                })
              )
              : (
                <div className="grid">
                  {infoPieces(pieceActuel)}
                </div>
              )}
          </Form>

        </FlexRow>

      </Container>

    </>
  );
}
